:root {
  --col-white: #fffcf2;
  --col-black: #252422;
  --col-gray: #403d39;
  --col-lt-gray: #ccc5b9;
  --col-orange: #eb5e28;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}
@media screen and (max-width: 750px) {
  .header {
    flex-direction: column;
    gap: 30px;
  }
}
.body {
  background-color: var(--col-black);
  min-height: 100vh;
  padding: 50px 100px;
}
@media screen and (max-width: 750px) {
  .body {
    padding: 40px 50px;
  }
}
@media screen and (max-width: 530px) {
  .body {
    padding: 40px 20px;
  }
}
.wiy {
  color: var(--col-lt-gray);
  margin: 0px;
  font-size: 30px;
}
.cost {
  color: var(--col-orange);
  margin: 0px;
  font-size: 100px;
  line-height: 0.7;
}
.subtitle {
  color: var(--col-lt-gray);
  margin: 0px;
  font-size: 20px;
}
.light {
  color: var(--col-white);
}
.orange {
  color: var(--col-orange);
}
.input {
  background-color: transparent;
  border: 3px solid var(--col-lt-gray);
  text-align: center;
  font-size: 20px;
  padding: 10px 20px;
  color: var(--col-lt-gray);
}
.input:focus {
  outline: none;
}
.inputRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: auto;
  justify-content: space-evenly;
}
@media screen and (max-width: 530px) {
  .inputRow {
    flex-direction: column;
    gap: 20px;
  }
  .inputRow * {
    width: 100%;
  }
}
.result {
  color: var(--col-gray);
  font-size: 40px;
  text-align: center;
  margin: 20px 0px;
  margin-bottom: 100px;
  transition: color 0.1s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.result .altered {
  color: var(--col-orange);
}
.result h1 {
  line-height: 0.7;
  margin: 0px;
}
@media screen and (max-width: 800px) {
  .result {
    flex-direction: column;
  }
}
.notes {
  position: relative;
  margin-bottom: 100px;
}
@media screen and (max-width: 530px) {
  .notes {
    margin-bottom: 120px;
  }
}
.notes .note {
  color: var(--col-lt-gray);
  margin: 0px;
  font-size: 20px;
}
.notes .title {
  font-weight: 900;
  color: var(--col-white);
  opacity: 0.6;
  position: absolute;
  transform: translateY(calc(-100% - clamp(0px, calc(var(--scroll) * 0.3px), 60px)));
  font-size: 80px;
  top: 0px;
  transition: opacity 0.1s;
  pointer-events: none;
}
@media screen and (max-width: 900px) {
  .notes .title {
    font-size: 60px;
    transform: translateY(calc(-100% - clamp(0px, calc(var(--scroll) * 0.3px), 45px)));
  }
}
@media screen and (max-width: 630px) {
  .notes .title {
    font-size: 50px;
    transform: translateY(calc(-100% - clamp(0px, calc(var(--scroll) * 0.3px), 35px)));
  }
}
.notes:hover .title {
  opacity: 0.3;
}
.soh {
  display: none;
}
.soh:hover {
  display: initial;
}
.link {
  color: var(--col-orange);
  text-decoration: none;
  position: relative;
}
.link::after {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 0px;
  right: 0px;
  height: 8px;
  width: 100%;
  background-color: var(--col-orange);
  opacity: 0.1;
  transition: 0.4s;
}
.link:hover::after {
  opacity: 0.8;
  height: 12px;
  bottom: 6px;
}
