@import url("https://fonts.googleapis.com/css2?family=Unica+One&display=swap");

html {
  background-color: var(--col-black);
}

body {
  margin: 0;
  font-family: "Unica One", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  max-width: 1000px;
  margin: auto;
}

* {
  font-weight: normal;
  font-family: "Unica One", sans-serif;
  box-sizing: border-box;
}

:root {
  --col-white: #fffcf2;
  --col-black: #252422;
  --col-gray: #403d39;
  --col-lt-gray: #ccc5b9;
  --col-orange: #eb5e28;
}
